import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FurtherReading from "../components/furtherReading"

const DataWebResources = () => (
  <Layout>
    <SEO
      title="The Best Data Science and Web Development Resources"
      description="Finally... the best list for data science and web development resources. Every online resource you need to start and progress in your programming career"
    />
    <h1>The Best Data Science and Web Development Resources</h1>
    <p>
      As you guys know, data science and web development are two of my favourite
      subjects. I've been lucky enough to be able to use what I have learned
      every day and now I think it's time to give back.
    </p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/1UHn3cV.jpg"
        alt="campbell data science resources web development"
        description="data science and web development list of best resources cds"
      />
    </div>
    <p>
      I used to write to experts in the field looking for secrets. One thing
      common answer I would get back, to my frustration, was 'read every day'.
      It took time but I realised there is no secret. To go far in any field,
      you have to keep learning. Find great resources and learn all the time
      then take action on what you have learned.
    </p>
    <p>
      Here's a list of the resources I used to get me where I am today. I hope
      you can take some things from this list and help you get to where to want
      to be. It will help you at any stage of your career and hopefully give you
      as much value as I have gained from it.
    </p>
    <p>
      Each section is divided into the following headers; Data Science, Web
      Development, Marketing, Tech.
    </p>
    <p>
      I hope for this page to be an ongoing list that I keep updated with the
      best resources for each of these topics. Get in touch with anything that
      has given you massive value that you want to share with others.
    </p>
    <p>
      I feel like I’m giving away my secrets but I’m here to help. Also, I
      stayed away from books for this because that's enough content to fill a
      whole different article. Check back soon for the book list.
    </p>
    <p>Let's get started....</p>
    <h2>Data Science Resources</h2>
    <p>
      <a
        href="https://www.youtube.com/watch?v=rkx5_MRAV3A"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        A hands-on introduction to Python for beginning programmers
      </a>
    </p>
    <p>
      Literally the video that started it all for me. If you have zero
      experience in programming or using python then start here. It couldn't
      have been a better introduction for me and I feel lucky to have found
      this.
    </p>
    <p>
      I learned so much by the time I finished the video and it gave me the
      confidence to keep going. If I ever meet Jessica McKellar one day, I will
      thank her and let her know how much she helped.
    </p>
    <p>
      <a
        href="https://greenteapress.com/thinkpython2/thinkpython2.pdf"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        ThinkPython2
      </a>
    </p>
    <p>
      Again, I feel lucky to have found this. It's a free pdf you can download
      through the link above. It is written in a way that uses the first section
      of a chapter to explain a concept with code along exercises. In the second
      section it gives you independent exercises to complete to cement what you
      just learned.
    </p>
    <p>
      When you finish this book you will have every skill you need to get
      started in the vast world of programming.
    </p>
    <p>
      <a
        href="https://www.superdatascience.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Super Data Science
      </a>
    </p>
    <p>
      Kirill Eremenko is a celebrity in the world of data science. He has many
      great resources including his website, book, multiple Udemy courses and
      podcast.
    </p>
    <p>
      The Super Data Science podcast - I listen on Soundcloud but he mentions
      iTunes so I'm sure you can find it on there. If you need a mentor from
      afar then add this to your playlist. He has interviewed many thought
      leaders in data science and releases content frequently. As you will
      learn, he makes the complex simple.
    </p>
    <p>
      Udemy courses - Honestly, if you take a few of the Super Data Science
      Courses with Kirill and Hedelin de Ponteves you will be ready to start a
      career in data science. Instead of going to university for at least 3
      years, you could take these courses, make a portfolio and find a job in
      data science.
    </p>
    <p>There are a few to choose from but here are the two I would suggest;</p>
    <p>python A-Z: Python For Data Science With Real Exercises!</p>
    <p>Machine learning A-Z - Hands-On Python & R In Data Science</p>
    <p>
      <a
        href="https://www.udemy.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Udemy/ Online Courses in General 
      </a>
    </p>
    <p>
      I’m always learning and I find online courses are great for learning
      quickly. They are put together by people who spend a lot of time finding
      the best content and doing research. If you wait for the discount sales,
      they are very cheap for what you get.
    </p>
    <p>
      <a
        href="https://www.kaggle.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Kaggle
      </a>
    </p>
    <p>
      Once you have learned the basics of data science and have gained some
      confidence in building models. Kaggle is a great place to get some
      practice. There are a lot of{" "}
      <a
        href="https://www.kaggle.com/datasets"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        public datasets
      </a>{" "}
      to choose from and there is a huge community.
    </p>
    <p>
      The one thing Kaggle is most well know for is their competitions. There is
      prize money for the winners, not small change either. Don't expect it to
      be easy to win though. Either way, it is a great experience and you get to
      see what it is like to build models for real world data. Although it has
      usually been cleaned already.
    </p>
    <p>
      <a
        href="https://www.kdnuggets.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        KDnuggets
      </a>
    </p>
    <p>
      An amazing resource for everything to do with data science. The site looks
      a bit crazy and outdated but you will quickly get past that because of the
      value you will gain.
    </p>
    <p>
      There are tutorials, datasets, jobs courses and the latest news in data
      science. It is definitely worth bookmarking KDnuggets site and checking
      back once in a while, or just subscribe.
    </p>
    <p>
      <a
        href="https://www.makeovermonday.co.uk/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Makeover monday
      </a>
    </p>
    <p>
      Makeover Monday is run by Eva Murray and Andy Kriebel, they educate in
      data visualisation using tools like Tableau. Don't feel put off if you
      don't know Tableau because they are knowledgeable in all things data
      visualisation and can help in any area.
    </p>
    <p>
      The site uploads a dataset every week along with a basic visualisation.
      Readers are encouraged to come up with their own visualisations that are
      both attractive and reveal information in the data.
    </p>
    <p>
      <a
        href="https://www.youtube.com/channel/UCrUL8K81R4VBzm-KOYwrcxQ/featured"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Engineer Man
      </a>
    </p>
    <p>
      A YouTube channel with well thought out and structures playlists for
      learning. If you are interested in doing some free python tutorials I
      recommend this. Try the python basics and python videos playlists.
    </p>
    <p>
      <a
        href="https://www.youtube.com/user/numberphile"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Numberphile
      </a>
    </p>
    <p>
      Not necessarily data science but if you find mathematics interesting then
      give this YouTube channel a watch. Their videos are educational and fun.
      They explain complex concepts in a simple way so it's nothing like reading
      complex mathematics papers. Great if you want an introduction to the
      subject.
    </p>
    <p>
      <a
        href="https://www.reddit.com/r/dataisbeautiful/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        r/dataisbeautiful
      </a>
    </p>
    <p>
      The Reddit community for data visualisation. I don't need to say too much
      about this one. If you are on Reddit join this community if you haven't
      already. If you don't have Reddit, join just for this community.
    </p>
    <h2>Web Development Resources</h2>
    <p>
      <a
        href="https://www.londonappbrewery.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        The London App Brewery
      </a>
    </p>
    <p>
      A great place to learn web development. If you have no experience or
      anywhere intermediate level then this is for you. Angela Yu is an amazing
      teacher and I would recommend her courses to anyone trying to get into web
      development.
    </p>
    <p>
      It it surprising how she takes subject that seem complex and makes them
      easy to understand. I personally took her web development bootcamp course.
      She updates the course and you can find the current one on Udemy. The
      Complete 2019 Web Development Bootcamp. Just have a look at the list of
      chapters if you are not sure.
    </p>
    <p>
      It's one of those things you find every so often that is worth much more
      than we pay for it. There is huge value here.
    </p>
    <p>
      <a
        href="https://stackoverflow.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Stack Overflow
      </a>
    </p>
    <p>
      You will most likely have heard of stack overflow so I won't need to go
      into detail here. The point is that as programmers, we can sometimes feel
      unworthy because we can't recall every single thing we learn.
    </p>
    <p>
      Well, just remember you don't have to. We can't know everything and we
      certainly won't remember everything so use stack overflow as much as you
      need to. Don't feel guilty.
    </p>
    <p>
      <a
        href="https://www.codecademy.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Code Academy
      </a>
    </p>
    <p>
      I am always amazed at how generous the programming and development
      community is. I have been part of other communities such as photography
      and media. None were as generous and friendly as programmers.
    </p>
    <p>
      It's like we clearly remember how difficult it was to begin with and don't
      want anyone else to go through it. It could be that teaching is one of the
      best ways to learn. I am not sure but I hope it never changes.
    </p>
    <p>
      That being said, I am amazed the resources on code academy are free. They
      have tutorials for just about anything in development. Just get over
      there, browse some of their courses and learn something new.
    </p>
    <p>
      <a
        href="https://www.reddit.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Reddit
      </a>
    </p>
    <p>
      A lot of time can be wasted on Reddit because it can be very entertaining
      so be careful. The Reddit community is known for being much smarter/
      wittier than other online communites so there is a lot to learn. Next to
      Quora that is and we'll get to that later.
    </p>
    <p>
      Here's a list of the communities I am part of for web/ mobile development.
      Have a quick browse and when you join the communities, check back every so
      often to keep updated with the latest and keep learning. 
    </p>
    <p>
      I follow quite a few more and you just need to browse to find communities
      for you but here are some I follow...
    </p>
    <p>
      <a
        href="https://www.reddit.com/r/javascript/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        r/javascript
      </a>
       - All about the JavaScript programming language
    </p>
    <p>
      <a
        href="https://www.reddit.com/r/web_design"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        r/web_design
      </a>
       - A community dedicated to all things web design
    </p>
    <p>
      <a
        href="https://www.reddit.com/r/webdev/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        r/webdev
      </a>
       - A community dedicated to all things web development: both front-end and
      back-end.
    </p>
    <p>
      <a
        href="https://www.reddit.com/r/node/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        r/node
      </a>
       - All about Node.js
    </p>
    <h2>Conclusion</h2>
    <p>
      I was going to add my resources for marketing and tech here but I just
      checked the word count and it will go well over 2000. I don't want make
      this article too long so stay tuned for the next part.
    </p>
    <p>
      This is probably for the best because I know some of you guys won't be
      interested in marketing and tech. I'm sure you will be eventually though
      because there is a natural progression and all of these fields complement
      each other well. If you wanted to implement these things but didn't want
      to learn them, you can read on{" "}
      <Link to="/outsourcing-with-fiverr/" style={{ textDecoration: "none" }}>
        article on outsourcing work
      </Link>{" "}
      to get an idea.
    </p>
    <p>
      I hope you get as much out of the resources as I did and still do.
      Remember that in the world of programming, it is essential to have a
      strategy to keep learning.
    </p>

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default DataWebResources
